<template>
    <div class="questions full-width" v-if="translates && translates[langUrl]">
        <div
            class="questions__main-image">
            <div class="questions__main-image__opacity"></div>
            <div class="questions__main-image__container">
                <div class="questions__main-image__container__text">
                    <div class="questions__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                </div>
                <div class="questions__main-image__container__directions">
                    <div
                        class="questions__main-image__container__direction"
                        @click="goTo('operator')"
                    >{{ translates['/operator'].pageNames_main[lang] }}</div>
                    <div
                        class="questions__main-image__container__direction"
                        @click="goTo('requirements')"
                    >{{ translates['/operator'].pageNames_requirements[lang] }}</div>
                    <div
                        class="questions__main-image__container__direction active"
                        @click="goTo('operator-questions')"
                    >{{ translates['/operator'].pageNames_questions[lang] }}</div>
                </div>
            </div>
        </div>
        <div class="questions__text">
            <div class="questions__text__title">{{ translates[langUrl].text_title[lang] }}</div>
            <div class="questions__text__comment">{{ translates[langUrl].text_comment[lang] }}</div>
        </div>
        <div class="questions__questions__container">
            <div class="questions__questions">
                <v-expansion-panels 
                    multiple
                    v-model="panel"
                    class="questions__questions__items">
                    <template v-for="(item, index) in pageTemplate.questions">
                        <v-expansion-panel
                            v-if="item.question[lang]"
                            :key="index"
                            class="questions__question"
                            >
                                <v-expansion-panel-header class="questions__question__header" v-html="item.question[lang]" />
                                <v-expansion-panel-content class="questions__question__content" v-html="item.answer[lang]" />
                        </v-expansion-panel>
                    </template>
                </v-expansion-panels>
            </div>
        </div>
        <div class="questions__pink-block" style="margin-bottom: 0">
            <div class="questions__pink-block__container">
                <div class="questions__pink-block__text">{{ translates[langUrl].pinkBlock_text[lang] }}</div>
                <BaseButton
                    class="button-fill"
                    style="border: 1px solid #FFFFFF !important;"
                    @click="goTo('operator')"
                >{{ translates[langUrl].button_operator[lang] }}</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS } from '@/vars';

    import BaseButton from '../../components/common/BaseButton.vue';
    import MobilePreview from '../../components/common/trip/PreviewCard';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'Questions',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle[this.lang]
            }
        },
        components: {
            BaseButton,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            tourSplideCounter: 1,
            panel: [0],
            langUrl: '/operator/questions'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.questionsOperator
            }),
        },
        created() {
            this.isDesktop = !isMobile();
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async back() {
                await this.$router.back();
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
        },
    };
</script>

<style lang="scss">
.questions {
    width: 100%;

    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../../assets/operators/Header.jpg) no-repeat;
        background-size: cover;
        background-position: center;

        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
            background: url(../../assets/operators/mobile-header.jpg) no-repeat;
            background-size: cover;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            // background: #000;
            opacity: 0.5;
        }
        &__container {
            position: absolute;
            width: 100%;
            height: 440px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            // justify-content: center;
            color: #FFFFFF;

            @media all and (max-width: 768px) {
                height: 356px;
                margin-top: 110px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1280px;
                margin: auto;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                }
            }
            &__directions {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                cursor: pointer;
                margin: 32px auto 43px;
                &::-webkit-scrollbar {
                    display: none;
                }
                @media all and (max-width: 768px) {
                    margin-top: 150px;
                    width: 375px;
                    margin: 110px auto 30px;
                }
            }
            &__direction {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                padding: 10px 24px;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                border-radius: 22px;
                margin-right: 8px;
                white-space: nowrap;
                @media all and (max-width: 768px) {
                    &:first-of-type {
                        margin-left: 20px;
                        padding: 8px 24px;
                    }
                }
                &.active {
                    background: #FFFFFF;
                    color: #3A4256;
                }
            }
        }
    }
    &__text{
        max-width: 1160px;
        margin: 80px auto 0;

        @media all and (max-width: 768px) {
            margin: 60px 0;
            padding: 0 20px;
        }
        &__title {
            margin: 0 auto 45px 0;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 32px;
            @media all and (max-width: 768px) {
                font-size: 28px;
                line-height: 34px;
                margin: 0 0 24px;
            }
        }
        &__comment {
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #273155;
            @media all and (max-width: 768px) {
                font-size: 16px;
            }
        }
    }
    &__pink-block {
        width: 100%;
        height: 300px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;

        @media all and (max-width: 768px) {
            height: 225px;
            margin-bottom: 60px;
        }
        &__container {
            width: 1400px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media all and (max-width: 1410px) {
                width: 100%;
                align-items: center;
                flex-direction: column;
                // justify-content: space-between;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                flex-direction: column;
            }
            .questions__pink-block__text {
                font-size: 46px;
                line-height: 56px;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    line-height: 130%;
                    margin-bottom: 20px;
                }
            }
        }
        &__icon {
            width: 55px;
            height: 55px;
            background: url(../../assets/brand/icon.png);

            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 52px;
            line-height: 63px;
            color: #FFFFFF;

            @media all and (max-width: 1410px) {
                margin-bottom: 20px;
            }
            @media all and (max-width: 768px) {
                font-size: 24px;
                line-height: 130%;
                text-align: center;
            }
        }
    }
    &__questions {
        max-width: 1160px;
        margin: 0 auto;

        @media all and (max-width:768px) {
            padding: 0 20px;
        }
        &__container {
            margin: 80px 0 0;
            padding: 80px 0;
            // display: flex;
            background: #F6F7F9;

            @media all and (max-width:768px) {
                margin: 60px 0 0;
                padding: 60px 0;
            }
        }
        &__title {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 60px;
        }
        &__items {
            border: none;
            border-radius: 12px;
        }
    }
    &__question {
        padding: 32px 34px;
        margin-bottom: 12px;
        border-radius: 12px;
        font-family: 'Inter';
        font-style: normal;

        &.v-expansion-panel--active {
            border: 1px #D80765 solid;
            border-radius: 12px !important;
            margin-top: 10px;
        }
        &__header {
            padding: 0;
            min-height: auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            color: #273155;
            border-radius: 12px;

            &.v-expansion-panel-header {
                font-size: 20px;
                padding: 0;
            }
            &.v-expansion-panel-header--active {
                min-height: auto;
                margin-bottom: 22px;
                @media all and (max-width:768px) {
                    margin-bottom: 16px;
                }
                .v-expansion-panel-header__icon {
                    ::before {
                        color: #D80765;
                    }
                }
            }
            
        }
        &__content {
            display: block;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #616D89;

            .v-expansion-panel-content__wrap {  
                padding: 0;
                max-width: 1000px;
            }
        }
    }
}
</style>